<template>
    <div class="add-new-travelling-order">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.invoiceCreate')"
            :title="$t('messages.invoiceCreate')"
            @submitPressed="submitPressed"
        />
        <invoice-form :invoiceObject="invoice" :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import InvoiceForm from './components/InvoiceForm.vue'

export default {
    components: {
        TitleBar,
        InvoiceForm
    },
    data() {
        return {
            action: null,
            invoice: {}
        }
    },
    methods: {
        submitPressed() {
            this.action = 'add'
        }
    }
}
</script>
